<template>
  <div>
    <v-row class="mt-3" no-gutters align="center" justify="center">
      <v-col cols="6">
        <v-card flat>
          <v-card-text class="d-flex justify-center pa-0">
            <template>
              <v-text-field placeholder="Enter a keyword to search for FAQ" class="text-h5" hide-details outlined
                height="55px" rounded v-model="searchKeyword" @click="updateExpand()" :append-icon="searchBarIcon"
                clear-icon="mdi-close-circle" @click:append="clearSearch()">
              </v-text-field>
              <template v-if="!getKioskProfile.data().physicalKeyboard">
                <v-icon size="35" class="ml-2" v-if="!expand" @click="expand = !expand">mdi-keyboard-outline</v-icon>
                <v-icon size="35" class="ml-2" v-else @click="
                  expand = !expand;
                clearSearch();
                searchId = '';
                ">mdi-keyboard-off-outline</v-icon>
              </template>
            </template>
          </v-card-text>
          <v-expand-transition>
            <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
              <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
              </SimpleKeyboard>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="3" class="mt-5" align="center" v-if="!expand && searchKeyword === '' && searchId === ''">
            <v-card width="95%" class="containerBorder" :height="cardHeight">
              <v-card-title class="justify-center primary--text">{{
                $t("categoryTitle")
              }}</v-card-title>
              <v-divider class="mx-3"></v-divider>
              <v-list class="overflow-y-auto" :height="cardHeight - 80">
                <v-list-item v-for="(categoryName, i) in faqCategory" :key="i">
                  <v-list-item-content>
                    <v-btn height="45" @click="
                      $store.commit('setSelectedFaqCat', {
                        category: categoryName,
                      })
                      " v-bind:color="getSelectedFaqCat.category === categoryName
                        ? 'secondary'
                        : 'primary'
                        ">{{ categoryName }}</v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col :cols="colLenght" class="mt-5" align="center">
            <v-card width="95%" class="containerBorder overflow-y-auto mb-2" :height="cardHeight">
              <v-expansion-panels focusable dark class="px-5 py-3"
                v-if="!expand && searchKeyword === '' && searchId === ''" popout v-model="panel">
                <v-expansion-panel v-for="(item, i) in filteredFaqs(getSelectedFaqCat.category)" :key="i" class="mt-2"
                  :value="item.question">
                  <v-expansion-panel-header color="primary" class="white--text text-h6" @click="clickedFaq(item)">
                    <template v-slot:actions>
                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                    </template>
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                    <v-row class="fill-height" no-gutters v-if="item.showMap || item.showQrCode">
                      <v-col cols="9">
                        <span v-html="item.answer"></span>
                      </v-col>

                      <v-col cols="3">
                        <v-row no-gutters>
                          <v-col v-if="item.showMap && getKioskProfile.data().kioskPremise === 'Inside'"
                            align-self="center" align="center">
                            <div v-for="(map, i) in assignedMaps(item.mapName)" :key="i">
                              <v-btn color="secondary" @click="showMap(map)" class="mb-3" width="85%">
                                <v-icon left dark medium>
                                  mdi-map-marker-radius
                                </v-icon>
                                {{ map }}
                              </v-btn>
                            </div>
                          </v-col>
                          <v-col v-if="item.showQrCode" align-self="center" align="center">
                            <v-btn color="secondary" class="mb-3" width="85%" @click="
                              sendLink(
                                item.qrInfo.urllist[0].link,
                                item.question
                              )
                              ">
                              <v-icon left dark medium>
                                mdi-link-variant
                              </v-icon>
                              {{ item.qrInfo.urllist[0].name }} </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        <span v-html="item.answer"></span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels focusable dark class="px-3" popout v-else v-model="panel">
                <v-expansion-panel v-for="(item, i) in matchingFaqs" :key="i" class="mt-2" :value="item.question">
                  <v-expansion-panel-header color="primary" class="white--text text-h6" @click="clickedFaq(item)">
                    <template v-slot:actions>
                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                    </template>
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                    <v-row class="fill-height" no-gutters v-if="item.showMap || item.showQrCode">
                      <v-col cols="9">
                        <span v-html="item.answer"></span>
                      </v-col>

                      <v-col cols="3">
                        <v-row no-gutters>
                          <v-col v-if="item.showMap && getKioskProfile.data().kioskPremise === 'Inside'"
                            align-self="center" align="center">
                            <div v-for="(map, i) in assignedMaps(item.mapName)" :key="i">
                              <v-btn color="secondary" @click="showMap(map)" class="mb-3" width="85%">
                                <v-icon left dark medium>
                                  mdi-map-marker-radius
                                </v-icon>
                                {{ map }}
                              </v-btn>
                            </div>
                          </v-col>
                          <v-col v-if="item.showQrCode" align-self="center" align="center">
                            <v-btn color="secondary" class="mb-3" width="85%" @click="
                              sendLink(
                                item.qrInfo.urllist[0].link,
                                item.question
                              )
                              ">
                              <v-icon left dark medium>
                                mdi-link-variant
                              </v-icon>
                              {{ item.qrInfo.urlist[0].name }}
                              </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        <span v-html="item.answer"></span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";
import moment from "moment";
const closest_match = require("closest-match");
export default {
  name: "faqs",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      tab: 0,
      input: "",
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      expand: false,
      cardHeight: 690,
      colLenght: "9",
      panel: 0,
      searchId: "",
    };
  },
  computed: {
    ...mapGetters([
      "getQnaKeyword",
      "getFaqCategory",
      "getSelectedFaqCat",
      "getKioskProfile",
      "getSessionId"
    ]),
    matchingFaqs() {
      let temp = [];
      let distance;
      if (this.searchKeyword !== "") {
        temp = this.$i18n.t("faqs").filter(faq => faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase()))
      } else if (this.searchId !== "") {
        temp = this.$i18n.t("faqs").filter(faq => faq.linkId.toString() === this.searchId)
      } else {
        temp = this.$i18n.t("faqs")
      }
      return temp;
    },
    faqCategory() {
      console.log('On FAQs page: ', this.$i18n
        .t("faqCategory")
        .map((category) => category.categoryName))
      return this.$i18n
        .t("faqCategory")
        .map((category) => category.categoryName);
    },
  },
  watch: {
    expand(newVal) {

      if (newVal) {
        this.searchBarIcon = "mdi-close-circle";
        console.log("focused")
        console.log(this.getKioskProfile.data().physicalKeyboard, "phy key ki val")
        if (this.getKioskProfile.data().physicalKeyboard) {
          this.cardHeight = 690
        }
        else {
          this.cardHeight = 450;
        }
        this.colLenght = 12;


      } else {
        console.log('Coming here in else: ', this.searchKeyword)
        if (this.searchKeyword !== "") {
          this.searchBarIcon = "mdi-close-circle";
        } else {
          this.searchBarIcon = "mdi-magnify";
          this.colLenght = 9
        }
        this.cardHeight = 690;
      }
    },
    searchId(newVal) {
      this.panel = 0
    },
    getQnaKeyword(newVal) {
      this.searchId = newVal;
      this.colLenght = 12;
      //this.searchKeyword = newVal
    },
    searchKeyword(newVal) {
      console.log("New Value at Search Keyword watch: ", newVal.trim().length);
      if (newVal === "" && newVal.trim().length < 1) {
        this.searchBarIcon = "mdi-magnify";
        console.log("line 432")

        if (this.getKioskProfile.data().physicalKeyboard) {
          this.colLenght = 9;
        }

      } else {
        this.searchBarIcon = "mdi-close-circle";
        this.colLenght = 12;
      }
    },
  },
  methods: {
    updateExpand() {
      if (this.getKioskProfile.data().physicalKeyboard) {
        this.expand = false
      } else {
        if (this.expand) {
          this.expand = false
        } else {
          this.expand = true
        }
      }
    },
    clearSearch() {
      this.searchKeyword = "";
      this.searchId = ""
      this.$store.commit("setClearInput", true)
    },
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      this.$store.commit("setClearInput", false)
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    filteredFaqs(category) {
      return this.$i18n
        .t("faqs")
        .filter((faq) =>
          faq.category.toLowerCase().includes(category.toLowerCase())
        );
    },
    assignedMaps(maps) {
      let assignedMap = maps.split(",");
      return assignedMap;
    },
    showMap(map) {
      this.$i18n.t("maps").forEach((mapper) => {
        if (mapper.mapName.toLowerCase().trim() === map.toLowerCase().trim()) {
          this.$store.dispatch("openImageViewer", {
            name: mapper.mapName,
            url: mapper.mapImage,
          });
          this.$store.dispatch("avatarSpeak", mapper.speech);
          this.tab = mapper.mapFloor - 1;
          this.$store.dispatch('addSessionDatatoStrapi', {
            applicationId: this.getKioskProfile.data().applicationId,
            applicationSessionId: this.getSessionId,
            timeStamp: moment().toISOString(),
            actionType: 'Touch',
            sessionPayload: {
              module: "Frequently Asked Question",
              action: "View Map",
              response: mapper.mapName,
            }
          })
        }
      });
    },
    sendLink(url, name) {
      console.log('URL : ', url)
      this.$store.dispatch(
        "avatarSpeak",
        "Please enter your Email ID or scan the QR Code to get this link."
      );
      this.$store.dispatch('addSessionDatatoStrapi', {
        applicationId: this.getKioskProfile.data().applicationId,
        applicationSessionId: this.getSessionId,
        timeStamp: moment().toISOString(),
        actionType: 'Touch',
        sessionPayload: {
          module: "Frequently Asked Question",
          action: "View PDF",
          response: name,
        }
      })
      this.$store.dispatch("openEmailViewer", {
        pdfType: "link",
        pdfName: name,
        pdfUrl: url,
      });
    },
    // Touch Usage
    clickedFaq(faq) {
      this.$store.dispatch('addSessionDatatoStrapi', {
        applicationId: this.getKioskProfile.data().applicationId,
        applicationSessionId: this.getSessionId,
        timeStamp: moment().toISOString(),
        actionType: 'Touch',
        sessionPayload: {
          module: "Frequently Asked Question",
          action: "Show Faq",
          response: faq.question,
        }
      })
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 70)}...`;
    },
  },
  mounted() {
    // Search Faq by keyword
    // this.searchKeyword = this.getQnaKeyword
    this.searchId = this.getQnaKeyword;
    console.log('Expand, Search Keyword and searchid ', this.expand, this.searchKeyword, this.searchId)
    if (this.searchKeyword !== "" || this.searchId !== "") {
      this.colLenght = 12;
    } else {
      console.log('Coming at mounted else')
    }
  },
};
</script>

<style>
.tabClass {
  background-color: #d37444 !important;
  color: white !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #2c6b87 !important;
  border-radius: 0px !important;
}

.PDFborder {
  border-style: solid;
  border-width: 2px;
  border-color: #2c6b87 !important;
  overflow-y: scroll;
  border-radius: 15px !important;
}

.linkCardBorder {
  border-color: #2c6b87 !important;
  border-bottom: solid;
  color: white !important;
  border-radius: 0px !important;
  margin-bottom: 2px;
  border-width: 0.5px;
}
</style>
<i18n>
  {
    "en": {
        "placeHolder": "Enter a keyword to search for FAQ",
        "categoryTitle": "CATEGORIES"
      },
    "es":{
        "placeHolder": "Introduzca una palabra clave para buscar preguntas frecuentes",
        "categoryTitle": "Categorías"
      }
  }
</i18n>