<template>
  <v-row no-gutters>
    <v-col cols="12" align="center">
      <v-img width="450" src="@/assets/logo.png" contain></v-img>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "languageBar",
  data() {
    return {
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Spanish', code: 'es-US' },
      ]
    }
  },
  computed: {
    ...mapGetters(["getTouchCount", "getCurrentLanguage", "getLanguages", "getenableADA"]),
  },
  methods: {
    pullLanguage(code) {
      return this.languages.filter(lang => lang.code === code)[0].name
    },
    adaTracking() {
      this.$store.commit("setTouchRequest", {
        module: "General",
        action: "ADA",
        response: "ADA enabled",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
    changeLanguage(language) {
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      switch (language) {
        case "en":
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$i18n.locale = "en";
          this.$store.commit("setCurrentLanguage", "en");
          this.$store.dispatch("avatarSpeak", "I am now speaking in english.");
          this.$store.dispatch('setDefaults')
          this.$store.dispatch("identifyAvatarLangCodes", "en");
          this.$store.commit("setSpeechRecognitionLanguage", "en-US")
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to English",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        case "es-US":
          //this.$i18n.locale = "es";
          this.$store.commit("setCurrentLanguage", "es-US");
          this.$store.dispatch('avatarSpeak', 'Ahora estoy hablando en español.');
          //this.$store.dispatch('setDefaultsSpanish')
          this.$store.dispatch("identifyAvatarLangCodes", "es-US");
          //this.$store.commit("setSpeechRecognitionLanguage", "es-US")
          // Track the touch count and action performed.
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to Spanish",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.btnDim {
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>